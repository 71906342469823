// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';
import { compareAsc } from '@/store/store';

export default {
	getUsers(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('User/GetUsers', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	addUser(user) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('User/AddUser', user)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	updateUser(user) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('User/UpdateUser', user)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	delUser(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`User/DeleteUser?userId=${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	sendEmailConfirmedCode(login) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`User/ReSendEmailConfirmedCode?login=${login}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRoles() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('User/GetRoles')
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegions() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('MedicalOrgs/Regions')
				.then((result) => {
					result.data = result.data.filter((f) => f.Name !== 'Тестовый регион');
					result.data.sort((a, b) => compareAsc(a.Name, b.Name));
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	uploadLogo(image) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append('File', image.target.files[0]);
			this.$http
				.post('User/UploadLogo', formData, { 'Content-Type': 'multipart/form-data' })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getMainCuratorForRegion(regionId) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`User/GetMainCuratorForRegion?regionId=${regionId}`)
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
