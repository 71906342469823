<template>
	<div>
		<div class="flex items-center mb-5">
			<h1 class="w-9/12 text-2xl font-bold items-center">Пользователи Системы</h1>
			<div class="flex justify-end w-4/12 items-center">
				<!-- <button
					type="button"
					class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					@click="openPopUp"
				>
					+ Добавить пользователя
				</button> -->

				<Button v-if="userIsInRoles(['Support', 'MZ_Employee'])" class="mr-2" text="+ Добавить пользователя" @click.native="openPopUp" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
			</div>
		</div>

		<div class="flex flex-col relative">
			<div
				class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default h-1/4"
				:class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }"
				:style="list.length > 9 ? 'height: calc(100vh - 320px)' : filterOpen || filterOpen === 0 ? 'min-height:320px' : ''"
			>
				<table id="table-minzdrav-users" class="w-full" style="table-layout: fixed">
					<thead>
						<tr class="h-16" :class="{ tr1__sticky: rowsVisible > 5 }">
							<th style="width: 50px" class="text-center border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">П/П</th>
							<th
								class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
								v-for="(item, ind) in fields"
								:key="'J' + ind"
								:style="item.width !== null ? 'width: ' + item.width : ''"
							>
								<span class="flex relative">
									<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
									<!-- Фильтр и сортировка -->
									<!-- <div
										class="flex bg-black50 bg-white rounded-xl absolute"
										:class="{ '-left-4 top-10': ind !== fields.length - 1, '-left-20 top-10': ind === fields.length - 1 }"
										v-if="filterOpen === ind"
									>
										<form class="flex m-auto flex-col items-start w-72 py-5 bg-white rounded-xl shadow-lg popup-filter" onsubmit="return false;">
											<h3 class="flex px-5 text-gray-400 font-bold uppercase justify-between items-center w-full">
												<span>Сортировка</span>
												<span
													class="cursor-pointer"
													@click="
														filterOpen = null;
														filterBy = null;
													"
													>X</span
												>
											</h3>
											<label class="mt-4 px-5 cursor-pointer font-normal">
												{{ item.label1 }}
												<input
													class="custom-input relative"
													type="radio"
													name="sortBy"
													@change="sortFilter(ind, false, item.fieldName)"
													v-model="item.sortBy"
													:value="sortAsc"
												/>
											</label>
											<label class="mt-4 px-5 cursor-pointer font-normal">
												{{ item.label2 }}
												<input
													class="custom-input relative"
													type="radio"
													name="sortBy"
													@change="sortFilter(ind, false, item.fieldName)"
													v-model="item.sortBy"
													:value="sortDesc"
												/>
											</label>
											<h3 class="flex items-center w-full mt-5 pt-5 px-5 border-t border-gray-400 text-gray-400 font-bold uppercase">
												Фильтр
												<button
													type="reset"
													class="ml-auto text-xs font-normal text-blue-500 underline normal-case cursor-pointer"
													@click="item.value1 || item.sortBy ? sortFilter(ind, true, item.fieldName) : ''"
												>
													Сбросить фильтр
												</button>
											</h3>
											<template v-if="item.filterValueType === 3">
												<label class="mt-4 px-5 cursor-pointer font-normal">
													да
													<input
														class="custom-input relative"
														type="radio"
														name="filterValue"
														@change="search(item, params.filters)"
														v-model="item.value1"
														:value="true.toString()"
													/>
												</label>
												<label class="mt-4 px-5 cursor-pointer font-normal">
													нет
													<input
														class="custom-input relative"
														type="radio"
														name="filterValue"
														@change="search(item, params.filters)"
														v-model="item.value1"
														:value="false.toString()"
													/>
												</label>
											</template>
											<template v-else>
												<input
													type="text"
													v-model="item.value1"
													v-filter="item.paramsFiter"
													@input="search(item, params.filters)"
													placeholder="Введите текст..."
													class="flex flex-grow mt-10 ml-3 pl-2 w-64 outline-none border-b border-gray-400"
												/>
											</template>
										</form>
									</div> -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:position="ind === fields.length - 1 ? 'right' : 'left'"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields"
										:defaultSortField="'regionName'"
										:item="item"
									/>
								</span>
							</th>
							<th
								v-if="userIsInRoles(['Support', 'MZ_Employee'])"
								class="w-1/12 px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
								style="width: 140px"
							>
								Действие
							</th>
						</tr>
					</thead>
					<tbody class="bg-white" v-for="(item, index) in list" :key="'G' + index">
						<tr>
							<td class="text-center text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="params.currentPage === 0">
									{{ index + 1 }}
								</span>
								<span v-if="params.currentPage > 0">
									{{ params.currentPage * params.pageSize + (index + 1) }}
								</span>
							</td>
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								{{ item.roles && item.roles.length > 0 ? item.roles[0] : '–' }}
							</td>
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								{{ item.regionName }}
							</td>
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								{{ item.fullName }}
							</td>
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								{{ item.login }}
							</td>
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								{{ item.email }}
							</td>
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<Icons :icon-name="item.emailConfirmed ? 'confirmed' : 'notConfirmed'" />
							</td>
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span class="flex">
									<svg class="m-auto" v-if="!item.isMainCurator" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_419_67593)">
											<path
												d="M13.4375 27.4375C6.025 27.4375 0 21.4125 0 14C0 6.5875 6.025 0.5625 13.4375 0.5625C20.85 0.5625 26.875 6.5875 26.875 14C26.875 21.4125 20.85 27.4375 13.4375 27.4375ZM13.4375 2.4375C7.0625 2.4375 1.875 7.625 1.875 14C1.875 20.375 7.0625 25.5625 13.4375 25.5625C19.8125 25.5625 25 20.375 25 14C25 7.625 19.8125 2.4375 13.4375 2.4375Z"
												fill="#C10016"
											/>
											<path d="M10 17.5625L17 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M17 17.5625L10 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</g>
										<defs>
											<clipPath id="clip0_419_67593">
												<rect width="26.875" height="26.875" fill="white" transform="translate(0 0.5625)" />
											</clipPath>
										</defs>
									</svg>
									<svg v-else class="m-auto" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_391_67823)">
											<path
												d="M13.5 27.4375C6.0875 27.4375 0.0625 21.4125 0.0625 14C0.0625 6.5875 6.0875 0.5625 13.5 0.5625C20.9125 0.5625 26.9375 6.5875 26.9375 14C26.9375 21.4125 20.9125 27.4375 13.5 27.4375ZM13.5 2.4375C7.125 2.4375 1.9375 7.625 1.9375 14C1.9375 20.375 7.125 25.5625 13.5 25.5625C19.875 25.5625 25.0625 20.375 25.0625 14C25.0625 7.625 19.875 2.4375 13.5 2.4375Z"
												fill="#27AE60"
											/>
											<path d="M8.0625 14.6177L12.5845 18.5625L19.0625 9.5625" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</g>
										<defs>
											<clipPath id="clip0_391_67823">
												<rect width="26.875" height="26.875" fill="white" transform="translate(0.0625 0.5625)" />
											</clipPath>
										</defs>
									</svg>
								</span>
							</td>
							<td v-if="userIsInRoles(['Support', 'MZ_Employee'])" class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span class="flex items-center">
									<button @click="editUser(item)" title="Редактировать" class="btn_default cursor-pointer bg-gray-300 hover:bg-blue-500">
										<edite />
									</button>
									<button
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										@click="openChangePasswordDialog(item)"
										title="Поменять пароль"
										class="btn_default cursor-pointer ml-2 bg-gray-300 hover:bg-blue-500"
									>
										<padlock />
									</button>
									<button
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										@click="deleteUser(item.id, index)"
										title="Удалить"
										class="btn_default ml-2 cursor-pointer bg-gray-300 hover:bg-red-500"
									>
										<trash />
									</button>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- <center v-if="!loadingTable && list.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center> -->
				<div v-if="!loadingTable && list.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
					<span class="w-full">Данных нет...</span>
				</div>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<div class="hidden sm:flex flex-row pt-6 pl-6 pr-20 pb-12 text-gray-400 text-sm">
					<div class="mr-auto">
						<p>Всего записей: {{ totalRows }}</p>
					</div>
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(50)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 50 }">50</li>
							<li @click="updateRow(100)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 100 }">100</li>
							<li @click="updateRow(200)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 200 }">200</li>
							<li @click="updateRow(null)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == null }">Все</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount === 0 ? 1 : totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
			</div>

			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>

		<PopUp :open="open" :title="popupTitle" @close="closePopup()">
			<div class="flex">
				<div class="w-full mr-5">
					<div class="input-box" :class="{ error: error.roles }">
						<label>Роль*</label>
						<v-select :error="error.roles" @input="changeRole" v-model="user.roles[0]" label="nameRF" :reduce="(option) => option.name" :options="roles" />
						<p class="error-text" v-if="error.roles">{{ error.roles }}</p>
					</div>
					<TextInput label="Е-mail*:" :error="error.email" v-model="user.email" />
					<TextInput label="Фамилия*:" :error="error.fname" v-model="user.fname" />
					<TextInput label="Отчество:" v-model="user.oname" />
					<TextInput v-if="viewOrganizationName" label="Наименование общественной организации*:" :error="viewOrganizationName && error.organizationName" v-model="user.organizationName" />
					<TextInput v-if="viewPosition" label="Должность*:" :error="viewPosition && error.position" v-model="user.position" />
				</div>
				<div class="w-full">
					<div class="input-box" :class="{ error: error.regionId }">
						<label>Регион</label>
						<v-select @input="changeRegion" v-model="user.regionId" label="Name" :error="error.regionId" :reduce="(option) => option.Id" :options="regions" />
						<p class="error-text" v-if="error.regionId">{{ error.regionId }}</p>
					</div>
					<TextInput label="Логин*:" :error="error.login" v-model="user.login" />
					<TextInput label="Имя*:" v-model="user.iname" :error="error.iname" />
					<TextInput v-if="viewWorkPlace" label="Место работы:" v-model="user.workPlace" />
					<TextInput label="Пароль*:" v-if="!update" :error="error.password" v-model="user.password" />
					<label class="flex label" v-if="viewPhotoDownload">
						<input class="hidden" type="file" name="" @change="addImage($event)" />
						<div class="ava" :style="{ backgroundImage: calcUrl(user.logoUrl) }">
							<Icons v-if="!user.logoUrl" icon-name="user" />
						</div>
						<div class="label-text">Загрузить фотографию</div>
					</label>
					<div class="input-box" v-if="showCurator || user.isMainCurator">
						<label class="flex label">Куратор <input type="checkbox" v-model="user.isMainCurator" class="ml-2" /></label>
					</div>
				</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup" />
				<Button size="medium" text="Сохранить" :disabled="saveDesabled" @click.native="validate" />
			</div>
		</PopUp>
		<PopUp :open="openConfirm" title="Вы уверены, что хотите сохранить пользователя?" @close="closeConfirmPopup()">
			<p>Перед сохранением пользователя необходимо скопировать пароль, чтобы отправить его пользователю после его регистрации в Системе. Вы уверены, что хотите сохранить пользователя?</p>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closeConfirmPopup" />
				<Button size="medium" text="Сохранить" :disabled="saveDesabled" @click.native="sendForm" />
			</div>
		</PopUp>
		<PopUp :open="changePasswordDialog" title="Смена пароля" @close="closeChangePasswordDialog()">
			<div class="input-box">
				<label>Логин:</label>
				<p class="text-gray-500">{{ changePasswordForm.login }}</p>
			</div>
			<TextInput label="Новый пароль:" :error="changePasswordError.password" v-model="changePasswordForm.password" />
			<TextInput label="Подтверждение пароля:" :error="changePasswordError.passwordConfirm" v-model="changePasswordForm.passwordConfirm" />
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closeChangePasswordDialog" />
				<Button size="medium" text="Сохранить" :disabled="changePasswordForm.disabled" @click.native="changePassword" />
			</div>
		</PopUp>

		<PopUp :open="openExport" size="400px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col overflow-auto">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcel(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Icons from '@/components/Icons.vue';
import debounce from 'lodash.debounce';
import edite from '../../../../assets/img/edite_for_btn.svg';
import padlock from '../../../../assets/img/padlock.svg';
import trash from '../../../../assets/img/trash_for_btn.svg';
import PopUp from '@/components/PopUp.vue';
import Button from '@/components/Button.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import TextInput from '@/components/TextInput.vue';
// Глобальные данные стора
import { getters, mutations, actions, methods } from '@/store/store';
// Локальные данные стора
import actionsLocal from '../store/actions';
import loadingComponent from '../../../../components/Loading.vue';
import FilterComponent from '@/components/Filter.vue';

const PasswordLength = 6;

export default {
	name: 'GuideTable',
	components: {
		loadingComponent,
		edite,
		padlock,
		trash,
		Icons,
		CheckboxGroup,
		PopUp,
		Button,
		TextInput,
		FilterComponent
	},
	computed: {
		...getters
	},
	data() {
		return {
			openExport: false,
			loadToExcell: false,
			saveDesabled: false,
			showCurator: false,
			regions: [],
			roles: [],
			osDisabled: false,
			update: false,
			open: false,
			openConfirm: false,
			popupTitle: '',
			user: {
				login: '',
				logoUrl: '',
				email: '',
				regionId: '',
				roles: [''],
				workPlace: '',
				organizationName: '',
				position: '',
				fname: '',
				iname: '',
				oname: '',
				password: ''
			},
			error: {
				login: null,
				email: null,
				roles: null,
				fname: null,
				iname: null,
				regionId: null,
				organizationName: null,
				position: null,
				password: null
			},
			changePasswordDialog: false,
			changePasswordForm: {},
			changePasswordError: {},
			viewOrganizationName: false,
			viewWorkPlace: false,
			viewPosition: false,
			viewPhotoDownload: false,
			loadingTable: false,
			rowsVisible: 100,
			totalRows: 0,
			totalCount: 0,
			page: 0,
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'Роль',
					fieldName: 'Roles',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'nameRF',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)',
					width: null
				},
				{
					Name: 'Регион',
					fieldName: 'RegionName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'Name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)',
					width: null
				},
				{
					Name: 'ФИО',
					fieldName: 'FullName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)',
					width: null
				},
				{
					Name: 'Логин',
					fieldName: 'Login',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)',
					width: null
				},
				{
					Name: 'E-mail',
					fieldName: 'Email',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)',
					width: null
				},
				{
					Name: 'Подтвержден',
					fieldName: 'EmailConfirmed',
					filterType: 1,
					filterValueType: 3,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию',
					width: '150px'
				},
				{
					Name: 'Куратор',
					fieldName: 'isMainCurator',
					filterType: 1,
					filterValueType: 3,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию',
					width: '110px'
				}
			],
			fieldsDownloadExcell: [
				{ title: 'Роль', id: 'Roles', checked: true },
				{ title: 'Регион', id: 'Region', checked: true },
				{ title: 'ФИО', id: 'FullName', checked: true },
				{ title: 'Логин', id: 'Login', checked: true },
				{ title: 'E-mail', id: 'Email', checked: true },
				{ title: 'Подтвержден', id: 'EmailConfirmed', checked: true },
				{ title: 'Куратор', id: 'MainCurator', checked: true }
			],
			sortAsc: 2,
			sortDesc: 1,
			list: [],
			defaultSortField: 'RegionName',
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 100,
				currentPage: 0
			}
		};
	},
	methods: {
		...mutations,
		...actions,
		...actionsLocal,
		...methods,
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params, true);
		},
		sortFilter(key, flag, fieldName) {
			for (let i = 0; i < this.fields.length; i += 1) {
				if (flag) {
					if (key === i) {
						this.fields[i].value1 = '';
						this.fields[i].sortBy = null;

						if (this.params.sortOption.fieldName === fieldName) {
							this.resetSort();
						}

						const index = this.params.filters.findIndex((f) => f.fieldName === fieldName);
						if (index >= 0) this.params.filters.splice(index, 1);

						break;
					}
				}

				if (key === i) {
					this.params.sortOption.sortType = this.fields[i].sortBy;
				}
			}
			if (!flag) this.params.sortOption.fieldName = fieldName;

			this.getData(this.params, true);

			this.switchFilterVisibility(null);
		},
		resetSort() {
			let sortField = this.fields.find((x) => x.fieldName === this.defaultSortField);

			if (sortField !== undefined) {
				sortField.sortBy = this.sortAsc;
				this.params.sortOption.sortType = sortField.sortBy;
				this.params.sortOption.fieldName = sortField.fieldName;
			}
		},
		search: debounce(function (item, array) {
			// если дата
			if (item.filterType === 3) {
				let dateStart = new Date(item.value1),
					it = item;
				it.value2 = new Date(dateStart.setDate(dateStart.getDate() + 1)).toISOString().substr(0, 10);
			}

			let obj = array.find((f) => f.fieldName === item.fieldName);

			if (array.length === 0 || !obj) {
				array.push(item);
			} else if (obj) {
				obj.value1 = item.value1;
			}

			for (let i = 0; i < this.params.filters.length; i += 1) {
				if (!this.params.filters[i].value1) {
					this.params.filters.splice(i, 1);
				}
			}

			this.getData(this.params, true);

			this.switchFilterVisibility(null);
		}, 1000),
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 0;
			}

			this.getUsers({
				filters: params.filters,
				sortOption: {
					fieldName: params.sortOption.fieldName,
					sortType: params.sortOption.sortType
				},
				pageSize: params.pageSize,
				currentPage: params.currentPage
			})
				.then((res) => {
					this.list = res.data.data;
					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				})
				.catch((err) => {
					if (err.response.data.includes('столбец')) {
						this.showNotification('Ошибка при фильтрации/сортировки, попробуйте снова!', 'error');
						this.loadingTable = false;

						return;
					}

					this.showNotification('Ошибка, обратитесь к Администратору!', 'error');
					this.loadingTable = false;
				});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		changeRole(e) {
			// Наименование общественной организации - текстовое поле (поле появляется при выборе одной из ролей Секретарь ОС, Член ОС, Председатель ОС)
			if (e === 'OS_Secretary' || e === 'Секретарь ОС' || e === 'OS_Member' || e === 'Член ОС' || e === 'OS_Chairman' || e === 'Председатель ОС') {
				this.viewOrganizationName = true;
				this.viewWorkPlace = true;
				this.viewPosition = true;
				this.viewPhotoDownload = true;
			} else {
				this.viewOrganizationName = false;
				this.viewWorkPlace = false;
				this.viewPosition = false;
				this.viewPhotoDownload = false;
			}
			let role = this.user.roles[0].split('_');
			this.osDisabled = role[0] === 'OS';
			this.showCurator = role[0] === 'OGV';

			this.setPaperOperatorEmail();
		},
		changeRegion() {
			this.setPaperOperatorEmail();
		},
		setPaperOperatorEmail() {
			if (this.user.roles[0] === 'OGV_PaperOperator' && this.user.regionId) {
				this.getMainCuratorForRegion(this.user.regionId).then((result) => {
					let mainCurator = result.data || null;
					if (mainCurator !== null) {
						this.user.email = mainCurator.email;
					}
				});
			}
		},
		generatPass() {
			let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
			let passwordLength = 8;
			for (let i = 0; i <= passwordLength; i += 1) {
				let randomNumber = Math.floor(Math.random() * chars.length);
				this.user.password += chars.substring(randomNumber, randomNumber + 1);
			}
		},
		openPopUp() {
			this.update = false;
			this.generatPass();
			this.popupTitle = 'Добавить пользователя';
			this.open = true;
		},
		editUser(item) {
			if (!item.roles) {
				item.roles = [''];
			}
			this.changeRole(item.roles[0]);
			this.user = { ...item, roles: JSON.parse(JSON.stringify(item.roles)) };
			this.popupTitle = 'Редактировать пользователя';
			this.open = true;
			this.update = true;
		},
		deleteUser(id, index) {
			if (confirm('Вы уверены, что хотите удалить?')) {
				this.delUser(id)
					.then((res) => {
						this.list.splice(index, 1);
						this.showNotification('Пользователь успешно удален!', 'success');
					})
					.catch((err) => {
						console.log(err);
						this.showNotification('Ошибка при удалении пользователя, обратитесь к Администратору!', 'error');
					});
			}
		},
		closePopup() {
			this.open = false;
			this.user = {
				login: '',
				logoUrl: '',
				email: '',
				regionId: '',
				roles: [''],
				workPlace: '',
				organizationName: '',
				position: '',
				fname: '',
				iname: '',
				oname: '',
				password: ''
			};
			this.resetFormErrors();
		},
		closeConfirmPopup() {
			this.openConfirm = false;
		},
		addImage(event) {
			this.uploadLogo(event).then((res) => {
				this.user.logoUrl = res.data;
			});
		},
		resetFormErrors() {
			this.error = {
				login: null,
				email: null,
				roles: null,
				iname: null,
				password: null,
				regionId: null,
				fname: null,
				position: null,
				organizationName: null
			};
		},
		validate() {
			this.resetFormErrors();
			if (!this.user.fname) {
				this.error.fname = 'Введите фамилию';
			}
			if (!this.user.iname) {
				this.error.iname = 'Введите имя';
			}
			if (!this.update && !this.user.password) {
				this.error.password = 'Введите пароль';
			}
			if (!this.user.login) {
				this.error.login = 'Введите логин';
			}
			if (!this.user.email) {
				this.error.email = 'Введите email';
			}
			if (!this.user.roles[0]) {
				this.error.roles = 'Выберите роль';
			}
			if (!this.user.regionId) {
				this.error.regionId = 'Выберите регион';
			}
			if (this.viewPosition && !this.user.position) {
				this.error.position = 'Введите должность';
			}
			if (this.viewOrganizationName && !this.user.organizationName) {
				this.error.organizationName = 'Введите наименование';
			}
			let errors = Object.values(this.error).some((item) => {
				return item !== null;
			});
			if (!errors) {
				if (!this.update) {
					this.openConfirm = true;
				} else {
					this.sendForm();
				}
			}
		},
		sendForm() {
			this.setValidRoleName();

			if (this.user.roles[0] === 'OGV_PaperOperator') {
				this.user.emailConfirmed = true;
			}

			if (!this.update) {
				this.saveDesabled = true;

				this.addUser(this.user)
					.then((res) => {
						let role = this.roles.find((f) => f.name === res.data.roles[0]);
						res.data.roles[0] = role ? role.nameRF : res.data.roles[0];

						this.list.unshift(res.data);
						this.closeConfirmPopup();
						this.closePopup();
						this.saveDesabled = false;
						this.showNotification('Пользователь успешно создан!', 'success');
					})
					.catch((err) => {
						if (err.response.data.includes('e-mail')) {
							this.saveDesabled = false;
							this.showNotification('Введите корректный E-mail!', 'error');
							this.error.email = 'Введите корректный email';

							return;
						}
						if (err.response.data.includes('Пользователь с таким логином уже существует')) {
							this.saveDesabled = false;
							this.showNotification('Пользователь с таким логином уже существует!', 'error');
							this.error.login = 'Введите корректный логин';

							return;
						}

						this.saveDesabled = false;
						this.showNotification('Ошибка при создании пользователя, обратитесь к Администратору!', 'error');
					});
			} else {
				this.saveDesabled = true;
				this.updateUser(this.user)
					.then((res) => {
						let role = this.roles.find((f) => f.name === res.data.roles[0]);
						res.data.roles[0] = role ? role.nameRF : res.data.roles[0];

						for (let i = 0; i < this.list.length; i += 1) {
							if (res.data.id === this.list[i].id) this.list[i] = { ...res.data };
						}

						this.saveDesabled = false;
						this.closeConfirmPopup();
						this.closePopup();
						this.showNotification('Пользователь успешно отредактирован!', 'success');
					})
					.catch((err) => {
						console.log(err);
						this.saveDesabled = false;
						this.showNotification('Ошибка при создании пользователя, обратитесь к Администратору!', 'error');
					});
			}
		},
		setValidRoleName() {
			let role = this.roles.find((x) => this.user.roles[0] === x.nameRF);
			if (role !== undefined) {
				this.user.roles[0] = role.name;
			}
		},
		openChangePasswordDialog(item) {
			this.changePasswordDialog = true;

			this.changePasswordForm = {
				userId: item.id,
				login: item.login,
				password: '',
				passwordConfirm: '',
				disabled: false
			};

			this.resetChangePasswordErrors();
		},
		closeChangePasswordDialog() {
			this.changePasswordDialog = false;
		},
		resetChangePasswordErrors() {
			this.changePasswordError = {
				password: null,
				passwordConfirm: null
			};
		},
		validateChangePassword() {
			this.resetChangePasswordErrors();

			if (!this.changePasswordForm.password) {
				this.changePasswordError.password = 'Введите пароль';
			}

			if (this.changePasswordForm.password.length < PasswordLength) {
				this.changePasswordError.password = 'Пароль должен содержать не менее 6 символов';
			}

			if (!this.changePasswordForm.passwordConfirm) {
				this.changePasswordError.passwordConfirm = 'Подтвердите пароль';
			}

			if (this.changePasswordForm.password !== this.changePasswordForm.passwordConfirm) {
				this.changePasswordError.passwordConfirm = 'Пароли не совпадают';
			}

			let formHasErrors = Object.values(this.changePasswordError).some((x) => {
				return x !== null;
			});

			return !formHasErrors;
		},
		changePassword() {
			if (!this.validateChangePassword()) {
				return;
			}

			this.changePasswordForm.disabled = true;

			let input = {
				id: this.changePasswordForm.userId,
				pass1: this.changePasswordForm.password,
				pass2: this.changePasswordForm.passwordConfirm
			};

			this.setPassword(input)
				.then(() => {
					this.closeChangePasswordDialog();
					this.showNotification('Пароль успешно изменен!', 'success');
				})
				.catch((error) => {
					this.changePasswordForm.disabled = false;
					this.showNotification('Ошибка при изменении пароля, обратитесь к Администратору!', 'error');
				});
		},
		calcUrl(url) {
			if (url.includes('http')) return url;

			return `https://apiosnok.minzdrav.gov.ru${url}`;
		},
		downloadExcel(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'User/GetUsersExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'НОК_Пользователи.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		}
	},
	mounted() {
		this.resetSort();
		this.getData(this.params, true);

		this.getRegions().then((result) => {
			this.fields[1].list = result.data;
			this.regions = result.data;
		});

		this.getRoles().then((res) => {
			this.fields[0].list = res.data;
			this.roles = res.data;
		});
	}
};
</script>

<style>
#table-minzdrav-users .icon svg {
	margin: 0 auto;
}

.error .vs__dropdown-toggle {
	border: 1px solid #e94b3d;
}
</style>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 7;
}
.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}
.btn_default {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
}

.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.ava {
	background: #f2f5ff;
	border-radius: 50%;
	width: 80px;
	overflow: hidden;
	margin-right: 20px;
	height: 80px;
	display: flex;
	background-size: cover;
	background-position: 50% 50%;
}

.ava .icon,
.ava img {
	margin: auto;
}

.th-content .table-cell,
.table-cell {
	padding: 20px;
}

.table-cell span {
	margin: auto 0;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-cell span.m-auto {
	margin: auto;
}

.custom-input:not(:checked) {
	display: none;
}

.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	z-index: 40;
}

.input-box {
	position: relative;
	margin-bottom: 20px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}
.error-text {
	color: #e94b3d;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}
</style>
